nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}

nav li {
  float: left;
  border-right:1px solid #bbb;
}

nav li:last-child {
  border-right: none;
}

nav li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

nav li a:hover:not(.active) {
  background-color: #111;
}

nav .active {
  background-color: #4CAF50;
}

.app {
    margin-top: 10px;
}

.table tr {
    transition: background 0.2s ease-in;
}

.table thead {
    background: #333333;
    color: white;
}

.table tbody tr:nth-child(odd) {
    background: silver;
}

.table tbody tr:hover {
    background: gray;
    cursor: pointer;
}
